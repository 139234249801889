import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Fade,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Helmet } from "react-helmet";

import { colors } from "../..";

import { useDispatch, useSelector } from "react-redux";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useTranslation } from "react-i18next";
import {
  changeCurrentPriceValue,
  changeTotalPriceValue,
  selectPricingList,
} from "../Pricing/pricingSlice";

import jigsaw_9 from "./Assets/jigsaw_9.png";
import jigsaw_100 from "./Assets/jigsaw_100.png";

import { UploadPuzzleModal } from "./utils/UploadPuzzleModal";
import { changePuzzleValue } from "./utils/createPuzzleSlice";

export const CreatePuzzle = () => {
  const { t } = useTranslation();
  const [puzzleImage, setPuzzleImage] = useState(jigsaw_9);
  const [openModal, setOpenModal] = useState(true);
  const [imageToSend, setImageToSend] = useState(null);
  const [menuIn, setMenuIn] = useState(false);
  const [imgRatio, setImgRatio] = useState(0);
  const dispatch = useDispatch();
  const [puzzleDimension, setPuzzleDimension] = useState("A4");
  const [nrOfPieces, setNrOfPieces] = useState(9);
  const priceList = useSelector(selectPricingList);
  const [puzzlePrice, setPuzzlePrice] = useState(0);
  const [isPortrait, setIsPortrait] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [dataUrl, setDataUrl] = useState(null);
  useEffect(() => {
    const price = priceList.find(
      (price) => price.material === puzzleDimension
    ).price;
    if (imageToSend !== null && !openModal) {
      dispatch(changeCurrentPriceValue(price));
      setPuzzlePrice(price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList, puzzleDimension, imageToSend]);

  useEffect(() => {
    dispatch(changeCurrentPriceValue(0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImageHanlder = async (event) => {
    var image = document.getElementById("output");
    var imageData = new Image();
    image.src = URL.createObjectURL(event.target.files[0]);

    imageData.src = URL.createObjectURL(event.target.files[0]);
    setImageToSend(imageData.src);

    imageData.onload = () => {
      //check is image is landscape or not
      const ratio = imageData.width / imageData.height;
      var canvas = document.createElement("canvas");
      canvas.width = imageData.width;
      canvas.height = imageData.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(imageData, 0, 0);
      const dataURL = canvas.toDataURL("image/png");

      setDataUrl(dataURL);
      if (ratio > 1) {
        setIsPortrait("Landscape");
      } else {
        setIsPortrait("Portrait");
      }
    };
    setOpenModal(false);
    setImgRatio(4 / 3);
  };

  const addPuzzleToCart = async () => {
    dispatch(
      changePuzzleValue({
        image: imageToSend,
        dimension: puzzleDimension,
        nrOfPieces: nrOfPieces,
        aspectRatio: imgRatio === 4 / 3 ? "4/3" : "16/9",
        price: parseInt(puzzlePrice),
        dataUrl: dataUrl,
      })
    );
    dispatch(changeCurrentPriceValue(0));
    dispatch(changeTotalPriceValue(parseInt(puzzlePrice)));
    setImgRatio(0);
    setOpenModal(true);
  };
  const resetImage = () => {
    dispatch(changeCurrentPriceValue(0));
    dispatch(changeTotalPriceValue(parseInt(puzzlePrice)));
    setImgRatio(0);
    setOpenModal(true);
  };
  const handlePuzzleChange = (event, value) => {
    value && setPuzzleDimension(value);
    if (value === "A3") {
      setPuzzleImage(jigsaw_100);
      setNrOfPieces(100);
    } else if (value === "A5") {
      setPuzzleImage(jigsaw_9);
      setNrOfPieces(9);
    } else {
      setPuzzleImage(jigsaw_100);
      setNrOfPieces(100);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      height="100%"
      padding="1%"
      overflow="hidden"
      sx={{
        minHeight: `calc(100vh - 110px)`,
      }}
      justifyContent="center"
    >
      <Helmet>
        <title>PrintWorks Configurator Stickere</title>
        <meta
          name="description"
          content="
          Bine ați venit pe pagina noastră web dedicată configuratorului de stickere online! Aici, vă oferim o experiență inovatoare și personalizată, permițându-vă să vă transformați ideile în realitate prin designul propriilor stickere. Cu o interfață intuitivă și prietenoasă, puteți alege forme, dimensiuni și culori și încărca imagini  pentru a crea exact produsul dorit. Beneficiați de preview live pentru a vedea cum evoluează designul în timp real și asigurați-vă că fiecare detaliu este perfect. Descoperiți creativitatea fără limite și comandați stickerele personalizate care vă definesc!"
        />
        <meta
          name="title"
          content="Printworks: Designul Tău, Stickerele Tale: Configureaza Online și Creează Stickere Personalizate Unice!"
        />
      </Helmet>
      <UploadPuzzleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpload={addImageHanlder}
      />
      <Grid item container xs={12} justifyContent="center">
        <Grid
          container
          item
          xs={12}
          marginTop="-55px"
          justifyContent="center"
          alignItems="center"
          height={`calc(100vh - 50px)`}
        >
          <canvas id="canvas" width="5000px" hegiht="5000px"></canvas>
          <img
            id="output"
            alt="poza"
            style={{
              maxWidth: matches ? "40%" : "80%",
              maxHeight: matches ? "70%" : "60%",
              width:
                isPortrait === "Landscape" ? (matches ? "40%" : "80%") : "auto",
              height:
                isPortrait === "Portrait" ? (matches ? "70%" : "60%") : "auto",
              display: imgRatio !== 0 ? "block" : "none",
              aspectRatio: isPortrait === "Portrait" ? 1 / imgRatio : imgRatio,
              position: "absolute",
              marginInline: "auto",
              objectFit: "cover",
            }}
          />

          {imgRatio !== 0 && (
            <img
              alt="contur"
              src={puzzleImage}
              style={{
                position: "absolute",
                maxWidth: matches ? "40%" : "80%",
                maxHeight: matches ? "70%" : "60%",
                width:
                  isPortrait === "Landscape"
                    ? matches
                      ? "40%"
                      : "80%"
                    : "auto",
                height:
                  isPortrait === "Portrait"
                    ? matches
                      ? "70%"
                      : "60%"
                    : "auto",
                aspectRatio:
                  isPortrait === "Portrait" ? 1 / imgRatio : imgRatio,
                marginInline: "auto",
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          bottom: 0,
          position: "fixed",
          background: "white",
          width: "100vw",
          right: 0,
          left: 0,
          minHeight: "10vh",
          padding: "10px",
          borderTop: `1px solid ${colors.text}`,
        }}
      >
        {!matches && (
          <Grid item xs={12}>
            <Button onClick={() => setMenuIn(!menuIn)}>
              {menuIn ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              <Typography>Menu</Typography>
            </Button>
          </Grid>
        )}
        <Fade in={menuIn || matches}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-evenly"
            display={menuIn || matches ? "flex" : "none"}
          >
            <Grid
              item
              container
              xs={12}
              sm={2}
              flexDirection="column"
              alignItems="center"
            >
              <Typography textAlign="center">Orientare</Typography>
              <ToggleButtonGroup
                color="dark"
                value={isPortrait}
                exclusive
                sx={{ border: "2px solid #FFBF00" }}
                onChange={(event, value) => value && setIsPortrait(value)}
                aria-label="Platform"
              >
                <ToggleButton value="Portrait">Portrait</ToggleButton>
                <ToggleButton value="Landscape">Landscape</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={2}
              flexDirection="column"
              alignItems="center"
            >
              <Typography textAlign="center">Forma imagine</Typography>
              <ToggleButtonGroup
                color="dark"
                value={imgRatio}
                exclusive
                sx={{ border: "2px solid #FFBF00" }}
                onChange={(event, value) =>
                  value && setImgRatio(parseFloat(value))
                }
                aria-label="Platform"
              >
                <ToggleButton value={16 / 9}>16/9</ToggleButton>
                <ToggleButton value={4 / 3}>4/3</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={2}
              flexDirection="column"
              alignItems="center"
            >
              <Typography textAlign="center">Dimensiune</Typography>

              <Tooltip title={t("puzzle.dimension.tooltip")}>
                <ToggleButtonGroup
                  color="dark"
                  value={puzzleDimension}
                  exclusive
                  sx={{ border: "2px solid #FFBF00" }}
                  onChange={handlePuzzleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="A3">A3</ToggleButton>
                  <ToggleButton value="A4">A4</ToggleButton>
                  <ToggleButton value="A5">A5</ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={2}
              flexDirection="column"
              alignItems="center"
            >
              <Typography textAlign="center">Numar piese</Typography>
              <ToggleButtonGroup
                color="dark"
                value={nrOfPieces}
                exclusive
                sx={{ border: "2px solid #FFBF00" }}
                onChange={(event, value) => {
                  value && setNrOfPieces(parseFloat(value));
                  value === 100 && setPuzzleImage(jigsaw_100);
                  value === 9 && setPuzzleImage(jigsaw_9);
                }}
                aria-label="Platform"
              >
                <ToggleButton value={9}>9</ToggleButton>
                {puzzleDimension !== "A5" && (
                  <ToggleButton value={100}>100</ToggleButton>
                )}
              </ToggleButtonGroup>
            </Grid>

            <Button onClick={resetImage}>{t("remove.image")}</Button>
            {matches && (
              <Button
                variant="contained"
                color="dark"
                startIcon={<AddShoppingCartIcon />}
                sx={{ position: "absolute", bottom: "15vh", right: "50px" }}
                onClick={addPuzzleToCart}
              >
                {t("add.to.cart")}
              </Button>
            )}
            {!matches && (
              <Button
                variant="contained"
                color="dark"
                startIcon={<AddShoppingCartIcon />}
                onClick={addPuzzleToCart}
              >
                {t("add.to.cart")}
              </Button>
            )}
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  );
};
