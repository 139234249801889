import React, { useState } from "react";
import {
  Modal,
  Grid,
  Button,
  Typography,
  // FormControlLabel,
  // Checkbox,
} from "@mui/material";
import { colors } from "../../..";
import i18next from "i18next";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

export const UploadPuzzleModal = (props) => {
  const { open, handleUpload } = props;
  // const [acceptRatio, setAcceptRatio] = useState(true);
  return (
    <Modal
      open={open}
      disableEnforceFocus
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
      }}
    >
      <Grid
        container
        item
        xs={8}
        md={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          background: colors.secondary,
          height: "230px",
          paddingY: "30px",
          borderRadius: "16px",
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <InsertPhotoIcon sx={{ fontSize: "70px" }} />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center">
            {i18next.t("upload.modal.text")}
          </Typography>
        </Grid>
        {/* <Grid item container xs={12} justifyContent="center">
          <FormControlLabel
            control={
              <Checkbox
                color="dark"
                sx={{ color: colors.customYellow }}
                checked={acceptRatio}
                onChange={(event) => setAcceptRatio(event.target.checked)}
              />
            }
            label={
              <Typography style={{ color: "red" }}>
                {i18next.t("acceptRatio")}
              </Typography>
            }
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            onChange={(event) => handleUpload(event)}
            type="file"
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span" color="dark">
              {i18next.t("upload.button")}
            </Button>
          </label>
        </Grid>
      </Grid>
    </Modal>
  );
};
