import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { colors } from "..";
import Header from "./Header";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Footer } from "./Footer";
import { routes } from "../config/routes";
import { getCurrentUser } from "../components/Auth/util/APIUtils";
import { ACCESS_TOKEN } from "../components/Auth/constants";
import { changeUserValues } from "../components/Auth/login/loginSlice";
import ErrorBoundary from "../utils/ErrorBoudary/ErrorBoudary";
import store from "../store/store";
import BackgroundImage from "../assets/background-pattern-10opacity1x.png";
import {
  getDiscounts,
  getStickerPrices,
} from "../components/Pricing/pricingSlice";
import { MobileModal } from "./components/MobileModal";

export const Layout = () => {
  const matches = useMediaQuery("(max-width:500px)");

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(matches);

  const handleLogout = (text) => {
    // eslint-disable-next-line no-restricted-globals
    const result = text === "initial" ? "" : confirm("Are you sure?");
    if (result || text === "initial") {
      localStorage.removeItem(ACCESS_TOKEN);
      store.dispatch({ type: "USER_LOGOUT" });
      dispatch(changeUserValues({ field: "user", value: {} }));
      dispatch(changeUserValues({ field: "authenticated", value: false }));
      navigate("/", { replace: true });
    }
  };
  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        dispatch(changeUserValues({ field: "user", value: response }));
        dispatch(changeUserValues({ field: "authenticated", value: true }));
        if (response.status === 401) {
          handleLogout("initial");
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    loadCurrentlyLoggedInUser();
    dispatch(getStickerPrices());
    dispatch(getDiscounts());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: 0,
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        background: colors.main,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "cover",
      }}
    >
      {/* <MobileModal open={openModal} handleClose={() => setOpenModal(false)} /> */}
      <ErrorBoundary />
      <Header handleLogout={handleLogout} />
      <Routes>
        {routes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            name={route?.title}
            element={<route.component />}
          />
        ))}
      </Routes>
      {location.pathname !== "/sticker" && location.pathname !== "/puzzle" && (
        <Footer />
      )}
    </Box>
  );
};
