import { combineReducers } from "redux";
import userSliceReducer from "../components/Auth/login/loginSlice";
import stickerSliceReducer from "../components/Sticker/createStickerSlice";
import orderHistoryReducer from "../components/OrderHistory/orderHistorySlice";
import errorBoudaryReducer from "../utils/ErrorBoudary/errorBoudarySlice";
import paymentFormReducer from "../components/PaymentForm/userAddressesSlice";
import priceSliceReducer from "../components/Pricing/pricingSlice";
import { openDB } from "idb";
import createPuzzleSliceReducer from "../components/Puzzle/utils/createPuzzleSlice";

export const appReducer = combineReducers({
  user: userSliceReducer,
  stickers: stickerSliceReducer,
  history: orderHistoryReducer,
  error: errorBoudaryReducer,
  addresses: paymentFormReducer,
  pricing: priceSliceReducer,
  puzzle: createPuzzleSliceReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    deleteItemFromIndexedDB("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

async function deleteItemFromIndexedDB(key) {
  var dbVersionString = localStorage.DB_VERSION || "0";
  var dbVersion = parseInt(dbVersionString, 10);
  dbVersion++;
  localStorage.DB_VERSION = "" + dbVersion;

  const db = await openDB("Printworks", dbVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("reduxPersist")) {
        db.createObjectStore("reduxPersist");
      }
    },
  });

  if (!db.objectStoreNames.contains("reduxPersist")) {
    console.error("Object store 'reduxPersist' not found.");
    return;
  }

  const tx = db.transaction("reduxPersist", "readwrite");
  const store = tx.objectStore("reduxPersist");

  await store.delete(key);

  await tx.done;
  await db.close();
}
