import { createSlice } from "@reduxjs/toolkit";

export const createPuzzleSliceReducer = createSlice({
  name: "createPuzzle",
  initialState: {
    formData: {
      puzzles: [],
    },
  },
  reducers: {
    changePuzzleValue: (state, action) => {
      state.formData.puzzles.push(action.payload);
    },
    removePuzzle: (state, action) => {
      state.formData.puzzles.splice(action.payload, 1);
    },
    resetPuzzleState: (state, action) => {
      state.formData.puzzles = [];
    },
  },
});

export const { changePuzzleValue, removePuzzle, resetPuzzleState } =
  createPuzzleSliceReducer.actions;
export const selectPuzzleList = (state) => state.puzzle.formData.puzzles;

export default createPuzzleSliceReducer.reducer;
